.reports {
  display: flex;
  justify-content: space-between;
  max-width: 100rem;
  margin: 0 auto;
  color: #1c1c0d;
  &__section {
    &:first-child {
      flex: 1 1 calc(35% - 1rem);
      max-width: calc(35% - 1rem);
      margin-right: 1rem;
    }
    &:last-child {
      flex: 1 1 calc(65% - 1rem);
      max-width: calc(65% - 1rem);
      margin-left: 1rem;
    }
    &--report {
      background-color: #fff;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px -2px;
      height: 8.5rem;
      &:not(:last-child) {
        margin-bottom: 2rem;
      }
      &-label {
        display: block;
        color: black;
        font-weight: normal;
        font-size: 1rem;
        margin: 0 0 0.25rem 0;
      }
      &-input {
        background-color: white;
        border: 2px solid #ce8d3b;
        border-radius: 0;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        color: black;
        width: 100%;
        cursor: pointer;
        &:focus {
          outline: none;
          border: 2px solid #ce8d3b;
        }
      }
    }
    &--inner {
      background-color: #fff;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px -2px;
      height: calc(10.5rem * 4 - 2rem);
    }
  }
  .date--full-width {
    width: 100%;
    & > div.react-datepicker-wrapper {
      width: 100%;
    }
  }
}
