.react-cookie-law-accept-all-btn {
  background-color: #ce8d3b;
  border: 2px solid;
  border-color: #ce8d3b;
  margin-left: 0.5rem;
  width: 10rem;
  font-weight: 600;
  font-size: 1.05rem;
  &:hover {
    background-color: #b97e36;
    border-color: #b97e36;
  }
}
.react-cookie-law-accept-selection-btn {
  color: #1c1c0d;
  background-color: transparent;
  border: 2px solid #1c1c0d;
  margin-left: 0.5rem;
  width: 10rem;
  font-weight: 600;
  font-size: 1.05rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}
