.dashboard {
  display: flex;
  justify-content: space-between;
  color: #1c1c0d;
  max-width: 100rem;
  margin: 0 auto;
  &__section {
    flex: 1 1 50%;
    max-width: 50%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &:first-child {
      flex: 1 1 calc(50% - 1rem);
      max-width: calc(50% - 1rem);
      margin-right: 1rem;
    }
    &:last-child {
      flex: 1 1 calc(50% - 1rem);
      max-width: calc(50% - 1rem);
      margin-left: 1rem;
    }
    &--container {
      background-color: #fff;
      padding: 2rem;
      border-radius: 8px;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px -2px;
      &-full {
        flex: 1 1 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2rem;
        height: 12rem;
        h3 {
          font-size: 2rem;
          color: #1c1c0d;
        }
        button {
          width: fit-content;
          font-weight: 700;
          font-size: 1rem;
          outline: none;
          color: #1c1c0d;
          border: 1px solid #1c1c0d;
          padding: 0.5rem 1.5rem;
          background-color: transparent;
          border-radius: 8px;
          &:hover {
            background-color: rgba(28, 28, 13, 0.05);
            text-decoration: underline;
          }
        }
      }
      &-half {
        flex: 1 1 calc(50% - 1rem);
        max-width: calc(50% - 1rem);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-right: 1rem;
        margin-bottom: 2rem;
        height: 12rem;
        &-even {
          flex: 1 1 calc(50% - 1rem);
          max-width: calc(50% - 1rem);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 1rem;
          margin-bottom: 2rem;
          height: 12rem;
          h4 {
            font-size: 1.7rem;
            font-weight: 300;
            margin: 0;
          }
          p {
            font-size: 1rem;
            font-weight: 300;
            color: #ce8d3b;
            margin: 0;
          }
        }
        h4 {
          font-size: 1.7rem;
          font-weight: 300;
          margin: 0;
          letter-spacing: 0.5px;
        }
        p {
          font-size: 1rem;
          font-weight: 300;
          color: #ce8d3b;
          margin: 0;
          letter-spacing: 0.5px;
        }
      }
      &-tall {
        height: 26rem;
        &-group {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        &-white {
          background-color: #fff;
        }
        h4 {
          font-size: 2.25rem;
          font-weight: 600;
          color: #1c1c0d;
        }
        p {
          font-size: 1.125rem;
          font-weight: 400;
        }
        button {
          width: 100%;
          font-weight: 700;
          font-size: 1rem;
          outline: none;
          color: #fff;
          border: 1px solid #1c1c0d;
          padding: 0.5rem 1.5rem;
          background-color: #1c1c0d;
          border-radius: 8px;
        }
      }
      &-graph {
        flex: 1 1 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 2rem;
        height: 30rem;
      }
    }
    &--icon {
      display: block;
      margin-bottom: 1rem;
      &-svg {
        height: 2.875rem !important;
        width: 2.875rem !important;
        filter: invert(67%) sepia(27%) saturate(1235%) hue-rotate(348deg) brightness(90%)
          contrast(77%);
      }
    }
    &--dark {
      background-color: #1c1c0d;
      color: #fff;
    }
    &--big {
      height: 15rem;
      h4 {
        font-size: 2.25rem;
        font-weight: 600;
      }
      p {
        font-size: 1.125rem;
        font-weight: 400;
      }
    }
    &--group {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: #fff;
      border-radius: 8px;
      padding: 2rem;
      box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px -2px;
      &-half {
        height: 12rem;
        margin-bottom: 1rem;
        &-even {
          height: 12rem;
          margin-top: 1rem;
        }
      }
      h4 {
        font-size: 1.75rem;
        font-weight: 300;
      }
      p {
        font-size: 1rem;
        font-weight: 300;
      }
    }
  }
}
.sponsors {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
