.rangeItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  &:nth-child(odd) {
    background: #f1f1f1;
  }
  &__cell {
    flex: 1 1 30%;
    max-width: 30%;
    &:last-child {
      flex: 1 1 20%;
      max-width: 20%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
    &:not(:first-child):not(:last-child) {
      flex: 1 1 50%;
      max-width: 50%;
      text-align: center;
    }

    &--input {
      background-color: white;
      border: 2px solid #ce8d3b;
      border-radius: 0;
      font-weight: normal;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      color: black;
      &:focus,
      &:focus-visible,
      &:active {
        border: 2px solid #ce8d3b;
        background-color: white;
        color: black;
        outline: none;
      }
    }
    &--text {
      display: block;
      margin: 0;
      padding: 0;
      font-weight: normal;
      color: black;
    }
    &--button {
      padding: 0.5rem 1rem;
      background-color: #c34b47;
      border: 2px solid #c34b47;
      border-radius: 0;
      font-weight: 500;
      font-size: 1rem;
      width: 50%;
      display: block;
    }
  }
}
