.modal {
  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }

  &__content {
    background: white;
    color: black;
    padding: 2.25rem;
    border-radius: 5px;
    width: 50rem !important;

    &--title {
      color: black;
      margin: 0;
      font-weight: 600;
      line-height: 1;
      font-size: 1.25rem;
    }
  }
  &__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    border: solid #f1f1f1;
    border-width: 2px 0 0;
    padding: 2rem 0 0;
    margin: 1rem 0 2rem;

    &--item {
      min-width: 12rem;
      // &:last-child {
      //   text-align: right;
      //   flex: 1 1 40%;
      // }
      &-header {
        color: black;
        font-size: 0.875rem;
        text-transform: uppercase;
        margin: 0 0 0.25rem;
        display: block !important;
      }
      &-description {
        font-size: 1.05rem;
        font-weight: normal;
        margin: 0 0 2rem 0;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
  &__close {
    background: #ce8d3b;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.1s ease;
    &:hover {
      opacity: 0.9;
    }
  }
}
