.order {
  background-color: white !important;
  color: black !important;

  &__flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    &--inner {
      flex: 1 1 50%;
      max-width: 50%;
      margin: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  &__container {
    padding: 0 3rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
    &:last-child {
      padding: 0 3rem 3rem;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    height: 8rem;
    padding: 0 3rem;
    h1 {
      color: black;
      margin: 0;
      font-weight: normal;
      line-height: 1;
      font-size: 1.75rem;
    }
  }
  &__subtitle {
    display: flex;
    align-items: center;
    height: 4rem;

    h2 {
      color: black;
      margin: 0;
      font-weight: semibold;
      line-height: 1;
      font-size: 1.25rem;
    }
  }
  &__details {
    padding: 2rem;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
    &--row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &-title {
        font-weight: normal;
        font-size: 1rem;
        margin: 0;
      }
      &-description {
        font-weight: semibold;
        font-size: 1rem;
        margin: 0;
      }
      &-header {
        &:first-child {
          flex: 1 1 40%;
          max-width: 40%;
        }
        &:not(:first-child) {
          flex: 1 1 20%;
          max-width: 20%;
        }
        margin: 0;
        padding: 0 0 0.5rem 0;
        border-bottom: 2px solid #f1f1f1;
        &:not(:first-child),
        :not(:last-child) {
          text-align: center;
        }
        &:last-child {
          text-align: right;
        }
      }
      &-info {
        &:first-child {
          flex: 1 1 40%;
          max-width: 40%;
        }
        &:not(:first-child) {
          flex: 1 1 20%;
          max-width: 20%;
        }
        font-weight: normal;
        margin: 0;
        &:not(:first-child),
        :not(:last-child) {
          text-align: center;
        }
        &:last-child {
          text-align: right;
        }
      }
    }
    &--product {
      margin: 0 !important;
      padding: 1rem 0;
      &:nth-child(odd) {
        background: #f1f1f1;
      }
    }
  }
  &__table {
    border: 2px solid #f1f1f1;
    border-radius: 8px;

    &--row {
      &:first-child {
        padding: 2rem 2rem 0;
        margin: 0 !important;
      }
      padding: 1rem 2rem;
    }
    &--description {
      text-align: left !important;
      margin: 0;
      &-secondary {
        text-align: left !important;
        margin: 0;
        font-size: 0.85rem;
        font-weight: bold;
        color: #ce8d3b;
      }
    }
  }
  &__separator {
    display: block;
    height: 0;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 2rem;
  }
}
