.grid-container {
    display: flex;
    /*flex-wrap: wrap;*/
    margin: auto;
}

.grid-square {
    box-sizing: border-box;
    border: 1px solid rgba( 255, 255, 255, 1); /* Optional: for seeing the grid lines */
    margin: -1px; /* Overlap borders to prevent doubling the border size */
}

.grid-cell {
    border: 1px solid rgb(110, 163, 112);
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    text-align: center;
}
