.editDeposit {
  background-color: white !important;
  color: black !important;
  padding-bottom: 3rem;
  &__title {
    display: flex;
    align-items: center;
    height: 8rem;
    padding: 0 3rem;
    h1 {
      color: black;
      margin: 0;
      font-weight: normal;
      line-height: 1;
      font-size: 1.75rem;
    }
  }
  &__create {
    padding: 0 3rem;
  }
  &__input {
    background-color: white;
    border: 2px solid #ce8d3b;
    border-radius: 0;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: black;
    &:focus {
      border: 2px solid #ce8d3b;
      background-color: white;
      color: black;
    }
    &--label {
      color: black;
      font-weight: normal;
      font-size: 1rem;
      margin: 0 0 0.25rem 0;
    }
  }
  &__separator {
    display: block;
    height: 0;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 2rem;
  }
  &__flex {
    display: flex;
    &--section {
      &:first-child {
        flex: 1 1 calc(50% - 1rem);
        margin-right: 1rem;
      }
      &:last-child {
        flex: 1 1 calc(50% - 1rem);
        margin-left: 1rem;
      }
    }
  }
  &__section {
    padding: 2rem;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
    margin: 0 3rem 2rem;

    &--title {
      display: flex;
      align-items: center;
      height: 4rem;
      margin: 0 3rem;
      h2 {
        color: black;
        margin: 0;
        font-weight: 600;
        line-height: 1;
        font-size: 1.25rem;

        span {
          font-size: 1rem;
          font-weight: normal;
        }
      }
    }
  }
  &__layout {
    position: relative;
    width: fit-content;
    padding: 1.25rem;
  }
  &__coordinate {
    position: absolute;
    font-size: 1rem;
    font-weight: 600;
    color: black;
    line-height: 1;
    &--start {
      top: 0;
      left: 0;
    }
    &--height {
      top: 0;
      right: 0;
    }
    &--width {
      bottom: 0;
      left: 0;
    }
  }
  &__table {
    padding: 0 !important;
    &--header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 !important;
      padding: 2rem 2rem 0;
      border-bottom: 2px solid #f1f1f1;
      &-cell {
        margin: 0;
        padding: 0 0 0.5rem 0;
        font-weight: 600;
        color: black;
        &:first-child {
          flex: 1 1 20%;
          max-width: 20%;
        }
        &:nth-child(2) {
          flex: 1 1 10%;
          max-width: 10%;
        }
        &:nth-child(3) {
          flex: 1 1 20%;
          max-width: 20%;
        }
        &:nth-child(4) {
          flex: 1 1 35%;
          max-width: 35%;
        }
        &:last-child {
          text-align: right;
          flex: 1 1 5%;
          max-width: 5%;
        }
        &:not(:first-child):not(:last-child) {
          text-align: center;
        }
      }
    }
  }
  &__action {
    background-color: #ce8d3b;
    width: 100%;
    outline: none;
    border: none;
    margin: 0 0 3rem;
    font-weight: 600;
    line-height: 1;
    font-size: 1.25rem;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 1.25rem 0;
  }
}
.color-black {
  color: black !important;
}
.warning-message {
  text-transform: uppercase;
  color: #c34b47;
  font-weight: 600;
}
