.logs {
  background-color: white !important;
  color: black !important;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px -2px;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    padding: 0 3rem;

    h1 {
      color: black;
      margin: 0;
      font-weight: normal;
      line-height: 1;
      font-size: 1.75rem;
    }
    &--search {
      display: flex;
      align-items: center;
      input {
        border-radius: 6px;
        background-color: white;
        border: 2px solid #ce8d3b;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        color: black;

        &:focus,
        :active,
        :focus-visible {
          border: 2px solid #b97e36;
          outline: none;
        }
      }
      button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ce8d3b;
        border: 2px solid #ce8d3b;
        border-radius: 6px;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.5rem 1rem !important;
        margin-left: 0.5rem;
        &:hover {
          background-color: #b97e36;
          border-color: #b97e36;
        }
        p {
          margin: 0 0 0 0.5rem;
          line-height: 1.2;
        }
      }
    }
  }
  &__separator {
    display: block;
    height: 0;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 2rem;
  }
  &__container {
    padding: 0 3rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
    &:last-child {
      padding: 0 3rem 3rem;
    }
  }
  &__subtitle {
    display: flex;
    align-items: center;
    height: 4rem;

    h2 {
      color: black;
      margin: 0;
      font-weight: 600;
      line-height: 1;
      font-size: 1.25rem;
    }
  }
  &__table {
    border: 2px solid #f1f1f1;
    border-radius: 8px;
    margin-bottom: 3rem;
    &--row {
      padding: 1rem 2rem;
      &:first-child {
        padding: 2rem 2rem 0;
        margin: 0 !important;
      }
    }
  }
  &__details {
    &--row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        border-bottom: 2px solid #f1f1f1;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &-header {
        flex: 1 1 40%;
        max-width: 40%;
        margin: 0;
        padding: 0 0 0.5rem 0;
        font-weight: 600;
        &:last-child {
          flex: 1 1 20%;
          max-width: 20%;
          text-align: right;
        }
      }
      &-info {
        flex: 1 1 40%;
        max-width: 40%;
        font-weight: normal;
        margin: 0;
        &:last-child {
          flex: 1 1 20%;
          max-width: 20%;
          text-align: right;
        }
      }
    }
    &--log {
      margin: 0 !important;
      cursor: pointer;
      transition: background-color 0.1s ease;
      &:nth-child(odd) {
        background: #f1f1f1;
      }
      &:hover {
        background-color: rgba(206, 141, 59, 0.2);
      }
    }
  }

  .button-left-margin {
    margin-left: 0.5rem;
  }
}

.filters {
  padding: 0 3rem 2rem;
  display: flex;
  align-items: center;
}
.filters-title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 5rem;
  height: 3.5rem;
  text-align: center;
  background-color: #ce8d3b;
  margin: 0 2rem 0 0;
  border: 2px solid #ce8d3b;
  font-weight: 600;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}
.filter-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 3.75rem;
  margin: 0 1.25rem;
}
.filter-name {
  color: black;
  margin: 0;
  font-size: 0.95rem;
}
.filter {
  input,
  select {
    height: 3.5rem;
    border-radius: 6px;
    background-color: white;
    border: 2px solid #ce8d3b;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: black;

    &:focus {
      border: 2px solid #b97e36;
      outline: none;
    }
    &::placeholder {
      font-size: 1rem;
    }
  }
}
