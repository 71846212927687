.groupsAdd {
  background-color: white !important;
  color: black !important;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px -2px;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    padding: 0 3rem;

    h1 {
      color: black;
      margin: 0;
      font-weight: normal;
      line-height: 1;
      font-size: 1.75rem;
    }
  }
  &__separator {
    display: block;
    height: 0;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 2rem;
  }
  &__container {
    padding: 0 3rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
    &:last-child {
      padding: 0 3rem 3rem;
    }
  }
  &__subtitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;

    h2 {
      color: black;
      margin: 0;
      font-weight: 600;
      line-height: 1;
      font-size: 1.25rem;
    }
    &--pagination {
      background-color: #f1f1f1;
      border-radius: 6px;
      border: none;
      outline: none;
      margin-left: 0.25rem;
      width: 2.25rem;
      height: 2.25rem;
      &:disabled {
        background-color: #f1f1f1 !important;
        opacity: 0.4;
      }
      &:hover {
        background-color: #e9e9e9;
      }
    }
  }
  &__table {
    position: relative;
    height: calc(54px + 31.55px * 5);
    overflow-y: scroll;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
    margin-bottom: 3rem;
    &--row {
      padding: 0.25rem 2rem;
      &:first-child {
        padding: 2rem 2rem 0;
        margin: 0 !important;
      }
    }
  }
  &__details {
    &--group {
      cursor: default;
    }
    &--row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        border-bottom: 2px solid #f1f1f1;
        position: absolute;
        width: 100%;
        background-color: white;
        top: 0;
      }
      &:nth-child(2) {
        margin-top: 54px !important;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &-header {
        flex: 1 1 40%;
        max-width: 40%;
        margin: 0;
        padding: 0 0 0.5rem 0;
        font-weight: 600;
        &:last-child {
          flex: 1 1 20%;
          max-width: 20%;
          text-align: right;
        }
      }
      &-info {
        flex: 1 1 40%;
        max-width: 40%;
        font-weight: normal;
        margin: 0;
        &:last-child {
          flex: 1 1 20%;
          max-width: 20%;
          text-align: right;
        }
      }
      &-button {
        width: 1.85rem !important;
        height: 1.85rem !important;
      }
    }
    &--user {
      margin: 0 !important;
      &:nth-child(odd) {
        background: #f1f1f1;
      }
    }
  }
  &__section {
    padding: 2rem;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
    margin: 0 0 2rem;

    &--title {
      display: flex;
      align-items: center;
      height: 4rem;
      margin: 0 3rem;
      h2 {
        color: black;
        margin: 0;
        font-weight: 600;
        line-height: 1;
        font-size: 1.25rem;

        span {
          font-size: 1rem;
          font-weight: normal;
        }
      }
    }
  }
  &__input {
    position: relative;
    background-color: white;
    border: 2px solid #ce8d3b;
    border-radius: 0;
    font-weight: 600;
    font-size: 1rem;
    padding: 0.5rem 1rem;
    color: black;
    width: 100%;
    &:focus {
      border: 2px solid #ce8d3b;
      background-color: white;
      color: black;
    }
    &[type='file'] {
      padding-left: 1rem;
      cursor: pointer;
      &::file-selector-button {
        top: 0;
        right: -4px;
        width: 8rem;
        border: none;
        background-color: #ce8d3b;
        color: #fff;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        cursor: pointer;
        position: absolute;
        &:hover {
          background: #b97e36 !important;
        }
      }
    }
    &--container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2rem;
      &-checkbox {
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        width: fit-content;
        margin-top: 2rem;
      }
    }
    &--checkbox {
      background-color: white;
      border: 2px solid #ce8d3b;
      border-radius: 0 !important;
      font-weight: 600;
      font-size: 1rem;
      color: black;
      margin-right: 0.5rem;
      &:checked {
        background-color: #ce8d3b;
        border-color: #ce8d3b;
      }
      &:focus {
        border-color: #ce8d3b;
      }
    }
    &--label {
      color: black;
      font-weight: normal;
      font-size: 1rem;
      margin: 0 0 0.25rem 0;
      &-checkbox {
        margin: 0 0 0 0.25rem;
      }
    }
    &--danger {
      background-color: var(--bs-danger);
      color: #fff;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      outline: none;
      border: none;
      width: 8rem;
    }
    &--add {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #ce8d3b;
      color: #fff;
      font-weight: 600;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      outline: none;
      border: none;
      width: 8rem;
    }
    &--preview {
      width: 100px;
      height: 36.5px;
      object-fit: contain;
      text-wrap: nowrap;
    }
  }
  &__checkbox {
    background-color: white;
    border: 2px solid #ce8d3b;
    border-radius: 0 !important;
    font-weight: 600;
    font-size: 1rem;
    color: black;
    margin-right: 0.5rem;
    &:checked {
      background-color: #ce8d3b;
      border-color: #ce8d3b;
    }
    &:focus {
      border-color: #ce8d3b;
    }
    &--label {
      color: black;
      font-weight: normal;
      font-size: 1rem;
      margin: 0 0 0.25rem 0;
      a {
        color: #ce8d3b !important;
        &:hover {
          color: #ce8d3b !important;
          text-decoration: underline !important;
        }
      }
    }
  }
  &__action {
    background-color: #ce8d3b;
    width: 100%;
    outline: none;
    border: none;
    font-weight: 600;
    line-height: 1;
    font-size: 1.25rem;
    text-transform: uppercase;
    border-radius: 5px;
    padding: 1.25rem 0;
    &:disabled {
      background-color: gray !important;
    }
    &:hover {
      background-color: #b97e36;
    }
  }

  .button-left-margin {
    margin-left: 0.5rem;
  }
}
