.custom-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-IFrame {
    width: 95%;
    height: 95%;
    position: fixed;
    margin: auto;
    overflow: hidden;
  }
  
  
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    border: none;
    background: none;
    font-size: 30px;
    cursor: pointer;
    color: white;
  }
  
  .iframe-style {
    width: 100%;
    height: 100%;
  }
  