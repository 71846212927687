.entityProduct {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #f1f1f1;
  &__container {
    display: flex;
    gap: 1rem;
    &--right {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      margin: 0.5rem 1rem 0.5rem 0;
    }
  }
  &__image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 5rem;
    height: 5rem;
    background-color: #ce8d3b;
    overflow: hidden;
    &--img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      &-placeholder {
        transform: scale(0.55);
        filter: invert(1);
      }
    }
  }
  &__details {
    margin: 0.5rem 0;
    &--title {
      margin: 0;
      font-size: 1.05rem;
      &-secondary {
        text-align: left !important;
        margin: 0;
        font-size: 0.9rem;
        font-weight: bold;
        color: #ce8d3b;
      }
    }
  }
  &__price {
    font-size: 1.05rem;
    margin: 0;
  }
  &__action {
    font-size: 0.95rem;
    color: #ce8d3b;
    margin: 0;
    &:hover {
      text-decoration: underline !important;
    }
  }
}
