.groups {
  background-color: white !important;
  color: black !important;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 1px 10px -2px;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 8rem;
    padding: 0 3rem;

    h1 {
      color: black;
      margin: 0;
      font-weight: normal;
      line-height: 1;
      font-size: 1.75rem;
    }
    &--buttons {
      display: flex;
      align-items: center;
    }
    &--search {
      input {
        border-radius: 6px;
        background-color: white;
        border: 2px solid #ce8d3b;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.5rem 1rem;
        color: black;

        &:focus,
        :active,
        :focus-visible {
          border: 2px solid #b97e36;
          outline: none;
        }
      }
      &-button {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: #ce8d3b;
        color: #fff;
        border: 2px solid #ce8d3b;
        border-radius: 6px;
        font-weight: 600;
        font-size: 1rem;
        padding: 0.5rem 1rem !important;
        margin-left: 0.5rem;
        &-secondary {
          background-color: #1c1c0d !important;
          border-color: #1c1c0d;
          &:hover {
            background-color: #38381a !important;
            border-color: #38381a !important;
          }
        }
        &:hover {
          background-color: #b97e36;
          border-color: #b97e36;
        }
        p {
          margin: 0 0 0 0.5rem;
          line-height: 1.2;
        }
      }
    }
  }
  &__separator {
    display: block;
    height: 0;
    border-bottom: 2px solid #f1f1f1;
    margin-bottom: 2rem;
  }
  &__container {
    padding: 0 3rem;
    &:not(:first-child) {
      margin-top: 2rem;
    }
    &:last-child {
      padding: 0 3rem 3rem;
    }
  }
  &__subtitle {
    display: flex;
    align-items: center;
    height: 4rem;

    h2 {
      color: black;
      margin: 0;
      font-weight: 600;
      line-height: 1;
      font-size: 1.25rem;
    }
  }
  &__table {
    overflow: hidden;
    border: 2px solid #f1f1f1;
    border-radius: 8px;
    margin-bottom: 3rem;
    &--row {
      padding: 1rem 2rem;
      &:first-child {
        padding: 2rem 2rem 0;
        margin: 0 !important;
      }
    }
    &--description {
      text-align: left !important;
      margin: 0;
      &-secondary {
        text-align: left !important;
        margin: 0;
        font-size: 0.85rem;
        font-weight: bold;
        color: #ce8d3b;
      }
    }
    &--name {
      display: flex;
      align-items: center;
    }
    &--logo {
      width: 5rem;
      height: 1.875rem;
      object-fit: contain;
      margin-right: 1rem;
    }
    &--status {
      font-size: 0.875rem;
      line-height: 1.25rem;
      letter-spacing: 0.005em;
      font-weight: 400;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      border-radius: 1rem;
      padding: 0.375rem 0.75rem;
      height: 2rem;
      width: 6rem;
      vertical-align: middle;
      letter-spacing: 0.015em;
      margin: 0;
      &-active {
        color: #436f00;
        background-color: #e9f5ce;
        border: 1px solid #9bc438;
      }
      &-disabled {
        color: #925400;
        background-color: #feefcb;
        border: 1px solid #e6ac28;
      }
      &-deleted {
        color: #bb2a33;
        background-color: #ffecee;
        border: 1px solid #ff99a1;
      }
    }
  }
  &__details {
    &--row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:first-child {
        border-bottom: 2px solid #f1f1f1;
      }
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      &-header {
        flex: 1 1 40%;
        max-width: 40%;
        margin: 0;
        padding: 0 0 0.5rem 0;
        font-weight: 600;
        &:last-child {
          flex: 1 1 20%;
          max-width: 20%;
          text-align: right;
        }
      }
      &-info {
        flex: 1 1 40%;
        max-width: 40%;
        font-weight: normal;
        margin: 0;
        &:last-child {
          flex: 1 1 20%;
          max-width: 20%;
          text-align: right;
        }
      }
    }
    &--group {
      margin: 0 !important;
      cursor: pointer;
      &:nth-child(odd) {
        background: #f1f1f1;
      }
      &:hover {
        background: rgba(206, 141, 59, 0.2);
      }
      &-active {
        background: rgba(206, 141, 59, 0.4) !important;
      }
    }
  }

  .button-left-margin {
    margin-left: 0.5rem;
  }
}
