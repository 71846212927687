.entityUser {
  background: #f1f1f1;
  padding: 1rem;
  width: 100%;
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &--title {
      font-weight: normal;
      font-size: 1rem;
      margin: 0;
    }
    &--description {
      font-size: 1rem;
      font-weight: 600;
      margin: 0;
      &-link {
        color: #ce8d3b;
        &:hover {
          text-decoration: underline !important;
        }
      }
    }
  }
}
