.rangeItemShelf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  &:nth-child(odd) {
    background: #f1f1f1;
  }
  &__cell {
    &:first-child {
      flex: 1 1 20%;
      max-width: 20%;
    }
    &:nth-child(2) {
      flex: 1 1 10%;
      max-width: 10%;
    }
    &:nth-child(3) {
      flex: 1 1 20%;
      max-width: 20%;
    }
    &:nth-child(4) {
      flex: 1 1 35%;
      max-width: 35%;
    }
    &:last-child {
      text-align: right;
      flex: 1 1 5%;
      max-width: 5%;
    }
    &:not(:first-child):not(:last-child) {
      text-align: center;
    }

    &--input {
      background-color: white;
      border: 2px solid #ce8d3b;
      border-radius: 0;
      font-weight: normal;
      font-size: 1rem;
      padding: 0.5rem 1rem;
      color: black;
      &:focus,
      &:focus-visible,
      &:active {
        border: 2px solid #ce8d3b;
        background-color: white;
        color: black;
        outline: none;
      }
    }
    &--text {
      display: block;
      margin: 0;
      padding: 0;
      font-weight: normal;
      color: black;
    }
    &--button {
      padding: 0.5rem 1rem;
      background-color: #c34b47;
      border: 2px solid #c34b47;
      border-radius: 0;
      font-weight: 500;
      font-size: 1rem;
      width: 50%;
      display: block;
    }
  }
}
