.backgroundPrimary {
  font-size: 0.875rem;
  background-color: #ce8d3b !important;
  color: white;
  outline: none;
  border: none;
  padding: 0.5rem 1.25rem;
  border-radius: 6px;

  &:hover {
    background-color: #b97e36 !important;
  }
}
